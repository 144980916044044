import React, { useEffect, type FC, type PropsWithChildren } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch, RootState } from '../store';
import { login } from '../store/userSlice';
import { Outlet } from 'react-router-dom';

const AuthToAPI: FC<PropsWithChildren> = () => {
  const user = useSelector((state: RootState) => state.authenticatedUser);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (user === null) {
      dispatch(login());
    }
  }, [dispatch, user]);

  if (user === null) {
    return <progress></progress>;
  }

  return <Outlet />;
};

export default AuthToAPI;
