import React from 'react';
import { createBrowserRouter } from 'react-router-dom';

import Dashboard from './pages/Dashboard';
import { MainLayout } from './pages/MainLayout';
import ErrorBoundary from './pages/ErrorPage';
import { Media } from './pages/Media';
import { Revenue } from './pages/Revenue';
import { RecipeActivation } from './pages/RecipeActivation';
import { ManageReports } from './pages/ManageReports';
import { Faq } from './pages/Faq';

import PageNotFound from './pages/PageNotFound';
import SignupPage from './pages/SignupPage';
import Unauthorized from './pages/Unauthorized';
import { Earnings } from './pages/Earnings';
import { AdminLayout } from './pages/AdminLayout';
import { AdminUsersNav } from './components/AdminUsersNav';
import { AdminApprovedUsers } from './components/AdminApprovedUsers';
import { AdminPendingUsers } from './components/AdminPendingUsers';
import { AdminRejectedUsers } from './components/AdminRejectedUsers';
import AuthToAPI from './wrapper-components/AuthToAPI';

export const router = createBrowserRouter([
  {
    element: <AuthToAPI />,
    children: [
      {
        path: '/admin',
        element: <AdminLayout />,
        errorElement: <ErrorBoundary />,
        children: [
          {
            path: 'users',
            element: <AdminUsersNav />,
            children: [
              { path: 'approved', element: <AdminApprovedUsers /> },
              { path: 'pending', element: <AdminPendingUsers /> },
              { path: 'rejected', element: <AdminRejectedUsers /> },
            ],
          },
        ],
      },
      {
        element: <MainLayout />,
        children: [
          {
            path: '/',
            element: <Dashboard />,
          },
          {
            path: 'reports',
            children: [
              { path: 'revenue', element: <Revenue /> },
              { path: 'media', element: <Media /> },
              { path: 'ra', element: <RecipeActivation /> },
              { path: 'manage', element: <ManageReports /> },
              { path: 'earnings', element: <Earnings /> },
            ],
          },
          {
            path: 'support',
            children: [{ path: 'faq', element: <Faq /> }],
          },
        ],
        errorElement: <ErrorBoundary />,
      },
    ],
  },
  {
    path: '/become-a-chicory-recipe-partner',
    element: <SignupPage />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/unauthorized',
    element: <Unauthorized />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: '*',
    element: <PageNotFound />,
    errorElement: <ErrorBoundary />,
  },
]);
