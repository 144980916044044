import {
  createReducer,
  createAction,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import { UserState, Publisher } from '../types';
import { RootState, AppDispatch } from '.';
import { chicoryFetcher } from '../utils';
import { setFeatureFlags } from './featureFlags';
import { availablePubsAction, selectedPubsAction } from './publisherSlice';

type IUserInstruction = {
  fetchedEmail: string;
  fetchedName: string;
  fetchedPicture: string;
  hasPremium?: boolean;
  role: 'partner' | 'admin';
  publishers: Publisher[];
};

export const logoutAction = createAction('LOGOUT');

export const login = createAsyncThunk<
  IUserInstruction,
  void,
  { state: RootState; dispatch: AppDispatch }
>('LOGIN', async (_, { getState, dispatch }) => {
  const data = await chicoryFetcher('/auth/profile').then((response) =>
    response.json()
  );
  if (data.showImpressions) {
    dispatch(setFeatureFlags({ showImpressions: true }));
  }

  const state = getState();

  const user: IUserInstruction = {
    fetchedEmail: data.email,
    fetchedName: data.name,
    fetchedPicture: data.picture,
    role: data.role,
    publishers: data.publishers,
  };

  // Ensure pubs in url are valid, remove if not
  const availablePubIds = user.publishers.map((pub) => pub.id);
  const validSelectedPubs = state.pubs.selectedPubs.filter((selectedPubId) =>
    availablePubIds.includes(selectedPubId)
  );

  dispatch(availablePubsAction({ availablePubs: user.publishers }));

  if (state.pubs.selectedPubs.length !== validSelectedPubs.length) {
    dispatch(selectedPubsAction({ selectedPubs: validSelectedPubs }));
  }

  return user;
});

// Define the reducer functions using createReducer
const initialUser: null = null;
export const userReducer = createReducer<UserState>(initialUser, (builder) => {
  builder.addCase(login.fulfilled, (_, action) => {
    return {
      email: action.payload.fetchedEmail,
      name: action.payload.fetchedName,
      picture: action.payload.fetchedPicture,
      role: action.payload.role,
      hasPremium: action.payload.publishers.some(
        (pub) => pub.isPremium === true
      ),
    };
  });
  builder.addCase(login.rejected, (_, { error }) => {
    console.error('login failed', error);
  });
  builder.addCase(logoutAction, () => {
    return null;
  });
});
